import { Entity } from '@/types'

export class Category implements Entity {
  id?: string
  name?: string
  masterCategoryId?: string

  constructor(id?: string, name?: string, masterCategoryId?: string) {
    this.id = id
    this.name = name
    this.masterCategoryId = masterCategoryId
  }
}

export class CreateCategory {
  name: string
  masterCategoryId: string | null
  newMasterCategoryName: string | null

  constructor(
    name: string,
    masterCategoryId: string | null,
    newMasterCategoryName: string | null
  ) {
    this.name = name
    this.masterCategoryId = masterCategoryId
    this.newMasterCategoryName = newMasterCategoryName
  }
}

export class UpdateCategory {
  id: string
  name: string
  masterCategoryId: string

  constructor(id: string, name: string, masterCategoryId: string) {
    this.id = id
    this.name = name
    this.masterCategoryId = masterCategoryId
  }
}
