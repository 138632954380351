
import Vue from 'vue'
import Component from 'vue-class-component'
import moment from 'moment'

@Component
export default class GlobalMixin extends Vue {
  mounted(): void {
    const locale = window.navigator.language
    if (locale) {
      moment().locale(locale)
    }
  }

  get isMobile(): boolean {
    return (this as any).$mq === 'mobile'
  }

  get currentBudgetId(): string {
    return this.$route.params.budgetId
  }

  get currentAccountId(): string {
    return this.$route.params.accountId
  }

  get formattedDate(): Function {
    return (date: string): string => {
      return moment(date).format('l')
    }
  }

  isScrolledIntoView(element: HTMLElement, offsetTop = 0): boolean {
    var scrollingParent = element.parentElement
      ? element.parentElement.parentElement
      : null
    if (!scrollingParent) {
      return false
    }

    var parentHeight = scrollingParent.offsetHeight
    var parentTop = scrollingParent.scrollTop
    var parentBottom = parentTop + parentHeight

    var elemTop = element.offsetTop
    var elemBottom = elemTop + element.offsetHeight
    return elemTop >= parentTop + offsetTop && elemBottom <= parentBottom
  }
}
