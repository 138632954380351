







































































import Vue from 'vue'
import Component from 'vue-class-component'
import { Account } from '@/store/accounts/types'
import { accountModule } from '@/store/accounts/module'
import AccountSidebarLink from './AccountSidebarLink.vue'
import BalanceLabel from '@/components/BalanceLabel.vue'
import MButton from '@/components/common/MButton.vue'
import IconLink from './IconLink.vue'
import moment from 'moment'
import { EventBus } from '@/EventBus'
import { ButtonType } from '../common/types'

@Component({
  components: {
    AccountSidebarLink,
    IconLink,
    BalanceLabel,
    MButton
  }
})
export default class Sidebar extends Vue {
  loading = false
  accounts = accountModule
  darkModeIcon = 'lightbulb-outline-on'

  ButtonType = ButtonType

  mounted(): void {
    this.updateThemeToggleIcon()
  }

  get allAccounts(): Account[] {
    return this.accounts.accounts
  }

  get allAccountsBalance(): number {
    return this.accounts.accountBalance()
  }

  get currentMonth(): string {
    return moment().format('YYYY-MM')
  }

  addAccountClicked(): void {
    EventBus.$emit('addAccountClicked')
  }

  isDarkMode(): boolean {
    return document.documentElement.getAttribute('data-theme') === 'dark'
  }

  updateThemeToggleIcon(): void {
    this.darkModeIcon = this.isDarkMode()
      ? 'lightbulb-outline'
      : 'lightbulb-on-outline'
  }

  toggleDarkMode(): void {
    EventBus.$emit('toggle-theme')
    this.updateThemeToggleIcon()
  }
}
