






































import Component, { mixins } from 'vue-class-component'
import { Account, AccountReconciliation } from '@/store/accounts/types'
import AccountMixin from '@/mixins/AccountMixin.vue'
import BalanceLabel from '@/components/BalanceLabel.vue'
import AmountInput from '@/components/AmountInput.vue'
import GlobalMixin from '@/mixins/GlobalMixin.vue'
import { accountModule } from '@/store/accounts/module'
import { EventBus } from '@/EventBus'
import MButton from '@/components/common/MButton.vue'
import MModalCard from '@/components/common/MModalCard.vue'
import { ButtonType } from '../common/types'

@Component({
  components: {
    BalanceLabel,
    AmountInput,
    MButton,
    MModalCard
  }
})
export default class ReconcileAccountForm extends mixins(
  AccountMixin,
  GlobalMixin
) {
  accountToReconcile: AccountReconciliation | null = null
  accounts = accountModule

  ButtonType = ButtonType

  created(): void {
    EventBus.$on(
      'reconcileAccountClicked',
      (account: Account, actualBalance: number) =>
        this.open(account, actualBalance)
    )
  }

  open(account: Account, actualBalance: number): void {
    if (!account.id) {
      return
    }

    this.accountToReconcile = new AccountReconciliation(
      account.id,
      actualBalance
    )
  }

  async reconcileAccount(): Promise<void | Account> {
    if (!this.accountToReconcile) {
      return
    }

    this.accounts
      .reconcileAccount(this.accountToReconcile)
      .then(() => (this.accountToReconcile = null))
  }

  get reconcileAccountActive(): boolean {
    return !!this.accountToReconcile
  }

  set reconcileAccountActive(value: boolean) {
    if (!value) {
      this.accountToReconcile = null
    }
  }
}
