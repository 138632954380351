
















import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'

@Component
export default class MCheckbox extends Vue {
  @Prop() value!: Array<any> | boolean
  @Prop({ default: true }) nativeValue!: any

  onChange(selected: boolean): void {
    if (Array.isArray(this.newValue)) {
      if (selected) {
        this.newValue.push(this.nativeValue)
      } else {
        const index = this.newValue.indexOf(this.nativeValue)
        if (index > -1) {
          this.newValue.splice(index, 1)
        }
      }
    } else {
      this.newValue = selected
    }
  }

  get isChecked(): boolean {
    if (Array.isArray(this.newValue)) {
      return this.newValue.indexOf(this.nativeValue) > -1
    } else {
      return this.newValue
    }
  }

  set newValue(newValue: Array<any> | boolean) {
    this.$emit('input', newValue)
  }

  get newValue(): Array<any> | boolean {
    return this.value
  }
}
