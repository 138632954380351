




















import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import Utils from '@/utils'

@Component
export default class BalanceLabel extends Vue {
  @Prop() balance!: number
  @Prop({ type: Boolean, default: false }) backgroundColoring!: boolean
  @Prop({ default: false }) zeroIsPositive!: boolean
  @Prop({ default: false }) absolute!: boolean
  @Prop({ default: true }) colored!: boolean
  @Prop({ default: false }) invert!: boolean

  get isPositive(): boolean {
    if (this.balance === 0 && !this.zeroIsPositive && this.invert) {
      return false
    }
    let result = this.balance > 0
    if (this.zeroIsPositive) {
      result = this.balance >= 0
    }
    return this.invert ? !result : result
  }

  get isNegative(): boolean {
    return this.invert ? this.balance > 0 : this.balance < 0
  }

  get displayBalance(): string {
    let result = this.balance
    if (result && this.absolute) {
      result = Math.abs(result)
    }
    return Utils.toCurrency(result)
  }
}
