var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',{staticClass:"balance-label",class:{
    'background-green': _vm.isPositive && _vm.backgroundColoring,
    'has-background-danger': _vm.isNegative && _vm.backgroundColoring,
    'has-background-grey-light':
      !_vm.isPositive && !_vm.isNegative && _vm.backgroundColoring,
    'color-green': _vm.isPositive && !_vm.backgroundColoring,
    'color-red': _vm.isNegative && !_vm.backgroundColoring,
    'has-text-grey': !_vm.isPositive && !_vm.isNegative && !_vm.backgroundColoring,
    'has-text-white': _vm.backgroundColoring
  },on:{"click":function($event){return _vm.$emit('click', $event)}}},[_vm._v(" "+_vm._s(_vm.displayBalance)+" "),_vm._t("default")],2)}
var staticRenderFns = []

export { render, staticRenderFns }