
































import Vue from 'vue'
import Component from 'vue-class-component'
import MButton from '@/components/common/MButton.vue'
import MTextField from '@/components/common/MTextField.vue'
import MModalCard from '@/components/common/MModalCard.vue'
import { Account, UpdateAccount } from '@/store/accounts/types'
import { accountModule } from '@/store/accounts/module'
import { EventBus } from '@/EventBus'
import { ButtonType } from '../common/types'

@Component({
  components: { MButton, MModalCard, MTextField }
})
export default class UpdateAccountForm extends Vue {
  accountToUpdate: UpdateAccount | null = null
  accounts = accountModule

  ButtonType = ButtonType

  created(): void {
    EventBus.$on('editAccountClicked', (account: Account) => this.open(account))
  }

  open(account: Account): void {
    if (!account.id) {
      return
    }
    this.accountToUpdate = new UpdateAccount(
      account.id,
      account.name,
      account.description,
      account.offBudget,
      account.lastReconciliation
    )
  }

  async updateAccount(): Promise<void | Account> {
    if (!this.accountToUpdate) {
      return
    }

    // this.loading = true
    this.accounts
      .updateAccount(this.accountToUpdate)
      .then(account => {
        this.updateAccountActive = false
        // this.loading = false
      })
      .catch(e => console.error('Failed to update account: ' + e))
  }

  get updateAccountActive(): boolean {
    return !!this.accountToUpdate
  }

  set updateAccountActive(value: boolean) {
    if (!value) {
      this.accountToUpdate = null
    }
  }
}
