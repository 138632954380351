


































import Component, { mixins } from 'vue-class-component'
import { Prop, Watch } from 'vue-property-decorator'
import { MathOperation } from '@/types'
import GlobalMixin from '@/mixins/GlobalMixin.vue'
import MTextField from '@/components/common/MTextField.vue'

@Component({
  components: { MTextField }
})
export default class AmountInput extends mixins(GlobalMixin) {
  @Prop() value!: number
  @Prop() disabled!: boolean

  amount: number = this.value / 100

  originalValue: number = this.value

  operation: MathOperation = MathOperation.NONE
  modifier: number = 0

  @Watch('value')
  onValueChange(value: number): void {
    this.originalValue = value
    this.amount = value / 100
  }

  mounted(): void {
    this.originalValue = this.value
    this.amount = this.value / 100
  }

  get computedAmount(): number {
    return this.amount
  }

  set computedAmount(newAmount: number) {
    this.amount = newAmount || 0
    const result = Math.round(this.amount * 100)
    this.$emit('input', result)
  }

  focus(): void {
    const input = this.$refs.input as HTMLInputElement
    input.focus()
  }

  onBlur(blurEvent: FocusEvent): void {
    if (this.operation !== MathOperation.NONE) {
      this.onModifierFinished()
    }
    this.$emit('blur', blurEvent)
  }

  onEnterPressed(): void {
    this.$emit('blur')
  }

  onEscapePressed(): void {
    this.$emit('input', this.originalValue)
    this.$nextTick(() => this.$emit('blur'))
  }

  onKeypress(keyevent: KeyboardEvent): void {
    if (keyevent.key === '+') {
      this.applyModifier()
      keyevent.preventDefault()
      this.operation = MathOperation.ADDITION
    } else if (keyevent.key === '-') {
      this.applyModifier()
      keyevent.preventDefault()
      this.operation = MathOperation.SUBTRACTION
    } else if (keyevent.key === '*') {
      this.applyModifier()
      keyevent.preventDefault()
      this.operation = MathOperation.MULTIPLICATION
    } else if (keyevent.key === '/') {
      this.applyModifier()
      keyevent.preventDefault()
      this.operation = MathOperation.DIVISION
    }
  }

  applyModifier(): void {
    const result = this.calculateCurrentAmount()
    if (this.operation !== MathOperation.NONE) {
      this.computedAmount = Math.round(result * 100) / 100
      this.modifier = 0
    }
  }

  calculateCurrentAmount(): number {
    if (this.operation === MathOperation.NONE) {
      return this.computedAmount
    }
    let result = 0
    if (this.operation === MathOperation.ADDITION) {
      result = this.computedAmount + this.modifier
    } else if (this.operation === MathOperation.SUBTRACTION) {
      result = this.computedAmount - this.modifier
    } else if (this.operation === MathOperation.MULTIPLICATION) {
      result = this.computedAmount * this.modifier
    } else if (this.operation === MathOperation.DIVISION) {
      if (this.modifier === 0) {
        result = this.computedAmount
      } else {
        result = this.computedAmount / this.modifier
      }
    }
    return Math.round(result * 100) / 100
  }

  onModifierFinished(): void {
    this.applyModifier()
    this.operation = MathOperation.NONE
  }

  onModifierCanceled(): void {
    this.operation = MathOperation.NONE
    this.modifier = 0
  }

  get modifierMessage(): string | null {
    if (this.operation === MathOperation.NONE) {
      return null
    }

    let symbol = ''
    if (this.operation === MathOperation.ADDITION) {
      symbol = '+'
    } else if (this.operation === MathOperation.SUBTRACTION) {
      symbol = '-'
    } else if (this.operation === MathOperation.MULTIPLICATION) {
      symbol = '*'
    } else if (this.operation === MathOperation.DIVISION) {
      symbol = '/'
    }

    return `${this.amount} ${symbol} ${
      this.modifier
    } = ${this.calculateCurrentAmount()}`
  }

  get operatorIcon(): string {
    switch (this.operation) {
      case MathOperation.ADDITION:
        return 'plus'
      case MathOperation.SUBTRACTION:
        return 'minus'
      case MathOperation.MULTIPLICATION:
        return 'multiplication'
      case MathOperation.DIVISION:
        return 'slash-forward'
      default:
        return ''
    }
  }
}
