export class Balance {
  accountId?: string
  balance?: number
  clearedBalance?: number
}

export class Account {
  id?: string
  name?: string
  description?: string
  offBudget: boolean
  lastReconciliation?: string

  constructor(
    id?: string,
    name?: string,
    description?: string,
    offBudget: boolean = false,
    lastReconciliation?: string
  ) {
    this.id = id
    this.name = name
    this.description = description
    this.offBudget = offBudget
    this.lastReconciliation = lastReconciliation
  }
}

export class CreateAccount {
  name: string | null
  description: string | null
  initialBalance: number
  offBudget: boolean

  constructor(
    name?: string,
    description?: string,
    initialBalance: number = 0.0,
    offBudget: boolean = false
  ) {
    this.name = name || null
    this.description = description || null
    this.initialBalance = initialBalance
    this.offBudget = offBudget
  }
}

export class UpdateAccount {
  id: string
  name: string | null
  description: string | null
  offBudget: boolean
  lastReconciliation: string | null

  constructor(
    id: string,
    name?: string | null,
    description?: string | null,
    offBudget: boolean = false,
    lastReconciliation?: string | null
  ) {
    this.id = id
    this.name = name || null
    this.description = description || null
    this.offBudget = offBudget
    this.lastReconciliation = lastReconciliation || null
  }
}

export class AccountReconciliation {
  accountId: string
  actualBalance: number

  constructor(accountId: string, actualBalance: number) {
    this.accountId = accountId
    this.actualBalance = actualBalance
  }
}
