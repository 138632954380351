











































import Vue from 'vue'
import Component from 'vue-class-component'
import MButton from '@/components/common/MButton.vue'
import MCheckbox from '@/components/common/MCheckbox.vue'
import MModalCard from '@/components/common/MModalCard.vue'
import MTextField from '@/components/common/MTextField.vue'
import { Account, CreateAccount } from '@/store/accounts/types'
import AmountInput from '@/components/AmountInput.vue'
import { accountModule } from '@/store/accounts/module'
import { EventBus } from '@/EventBus'
import { ButtonType } from '../common/types'

@Component({
  components: { AmountInput, MButton, MCheckbox, MModalCard, MTextField }
})
export default class CreateAccountForm extends Vue {
  accountToCreate: CreateAccount | null = null
  accounts = accountModule

  ButtonType = ButtonType

  created(): void {
    EventBus.$on('addAccountClicked', () => this.open())
  }

  open(): void {
    this.accountToCreate = new CreateAccount()
  }

  async createAccount(): Promise<void | Account> {
    if (!this.accountToCreate) {
      return
    }

    // this.loading = true
    this.accounts
      .create(this.accountToCreate)
      .then(account => {
        this.accountToCreate = null
        // this.loading = false
        if (account.id) {
          this.$router.push({
            name: 'account-transactions',
            params: { accountId: account.id }
          })
        }
      })
      .catch(e => console.error('Failed to create account: ' + e))
  }

  get createAccountActive(): boolean {
    return !!this.accountToCreate
  }

  set createAccountActive(value: boolean) {
    if (!value) {
      this.accountToCreate = null
    }
  }
}
