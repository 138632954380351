
































































import Vue from 'vue'
import Sidebar from '@/components/sidebar/Sidebar.vue'
import MButton from '@/components/common/MButton.vue'
import Component from 'vue-class-component'
import { Watch, Prop } from 'vue-property-decorator'
import CreateAccountForm from '@/components/modals/CreateAccountForm.vue'
import ReconcileAccountForm from '@/components/modals/ReconcileAccountForm.vue'
import UpdateAccountForm from '@/components/modals/UpdateAccountForm.vue'
import { ButtonType } from '@/components/common/types'

@Component({
  components: {
    Sidebar,
    CreateAccountForm,
    ReconcileAccountForm,
    UpdateAccountForm,
    MButton
  }
})
export default class Default extends Vue {
  @Prop() title!: string
  @Prop() titleIcon!: string
  @Prop() titleIconColorClass!: string

  sidenavOpen: boolean = true

  ButtonType = ButtonType

  mounted(): void {
    if (this.isMobile) {
      this.sidenavOpen = false
    }
  }

  @Watch('$route')
  onRouteChange(newVal: any): void {
    if (this.isMobile) {
      this.sidenavOpen = false
    }
  }

  onMenuClicked(): void {
    this.sidenavOpen = !this.sidenavOpen
  }

  onOverlayClicked(): void {
    this.sidenavOpen = false
  }

  private get isMobile(): boolean {
    return (this as any).$mq === 'mobile'
  }
}
