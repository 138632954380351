















import Vue from 'vue'
import Component from 'vue-class-component'
import { Account } from '@/store/accounts/types'
import { accountModule } from '@/store/accounts/module'
import { Prop } from 'vue-property-decorator'
import BalanceLabel from '@/components/BalanceLabel.vue'
import IconLink from './IconLink.vue'

@Component({
  components: {
    BalanceLabel,
    IconLink
  }
})
export default class AccountSidebarLink extends Vue {
  @Prop() account!: Account

  loading: boolean = false
  accounts = accountModule

  mounted(): void {
    if (this.account) {
      this.loading = true
      this.accounts.loadBalance(this.account.id).then(() => {
        this.loading = false
      })
    }
  }

  get balance(): number {
    return this.accounts.accountBalance(this.account.id)
  }
}
