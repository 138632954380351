










import Component from 'vue-class-component'
import Vue from 'vue'
import { Prop } from 'vue-property-decorator'

@Component
export default class IconLink extends Vue {
  @Prop() icon!: string
  @Prop() iconClass!: string
  @Prop() routeName!: string
  @Prop() params!: any

  get focusBasedIconClass(): string {
    if (this.hasFocus) {
      return this.iconClass
    }
    return 'color-light-gray'
  }

  get hasFocus(): boolean {
    return (this.$root as any).hasFocus
  }
}
