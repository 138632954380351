
import Vue from 'vue'
import Component from 'vue-class-component'
import { accountModule } from '@/store/accounts/module'
import { Account } from '@/store/accounts/types'

@Component
export default class TransactionEditingMixin extends Vue {
  accounts = accountModule

  get allAccounts(): Account[] {
    return this.accounts.accounts
  }

  get account(): Function {
    return (accountId: string | undefined | null): Account | null => {
      return accountId ? this.accounts.account(accountId) || null : null
    }
  }

  get accountName(): Function {
    return (accountId: string | undefined | null): string => {
      if (!accountId) {
        return ''
      }
      const account = this.accounts.account(accountId)
      return account ? account.name || '' : ''
    }
  }

  get accountBalance(): Function {
    return (accountId: string) => {
      return this.accounts.accountBalance(accountId)
    }
  }
}
