export interface Entity {
  id?: string
  name?: string
}

export class DateRange {
  from: Date | null
  until: Date | null

  constructor(from?: Date, until?: Date) {
    this.from = from || null
    this.until = until || null
  }
}

export enum MathOperation {
  NONE = 'NONE',
  ADDITION = 'ADDITION',
  SUBTRACTION = 'SUBTRACTION',
  MULTIPLICATION = 'MULTIPLICATION',
  DIVISION = 'DIVISION'
}
